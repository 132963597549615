import env from '$lib/env.dynamic.public';
import { setupServices } from '$lib/services/service-registry';
import { clientServiceRegistry, setupClientServices } from '$lib/services/service-registry.client';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry, replayIntegration, browserTracingIntegration } from '@sentry/sveltekit';

// setup services once when client loads
setupServices(clientServiceRegistry)
setupClientServices(clientServiceRegistry)

Sentry.init({
  dsn: env.PUBLIC_SENTRY_DSN,

  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.05,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration(), browserTracingIntegration()],
//   tracePropagationTargets: ["localhost", "https://app.wyda.net", /^https:\/\/api\.wyda\.net/, /^https:\/\/app\.wyda\.net\/api/]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
